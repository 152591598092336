body {
  color: $body-color;
  font-family: sans-serif;
}

.slider-nav {
  div {
    color: white;

    h3 {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
  }
}

.slider-for {
  div {
    background-color: gray;
    color: black;
  }
}

.is-active {
  background-color: blue;
}
